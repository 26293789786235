<template>
    <div class="mt-5 w-full">
        <div class="firstRow">
            <div class="english">
                <vs-input
                    label="Service Name in KMs"
                    v-model="serviceDetails.km"
                    size="medium"
                    class="input"
                    danger-text="This field is mandatory"
                />
            </div>
            <div class="arabic">
                <vs-input
                    label="Price"
                    v-model="serviceDetails.price"
                    size="medium"
                    class="input"
                    danger-text="This field is mandatory"
                />
            </div>
        </div>

        <h6 class="vs-input--label label">Services Details Section:</h6>
        <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
            v-for="(detail, i) in serviceDetails.details"
            :key="i"
        >
            <vs-row
                vs-align="center"
                vs-type="flex"
                vs-justify="space-between"
                vs-w="12"
            >
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    class="pa-4"
                    vs-align="center"
                    vs-w="5"
                >
                    <vs-input
                        :label="`English Details NO.${i + 1}`"
                        v-model="detail.en"
                        rows="7"
                        class="mt-5 w-full"
                    />
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    class="pa-4"
                    vs-align="center"
                    vs-w="5"
                >
                    <vs-input
                        :label="`Arabic Details NO.${i + 1}`"
                        v-model="detail.ar"
                        rows="7"
                        class="mt-5 w-full"
                    />
                </vs-col>

                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    class="pa-4"
                    vs-align="center"
                    vs-w="2"
                >
                    <button
                        v-if="
                            serviceDetails.details.indexOf(
                                serviceDetails.details[
                                    serviceDetails.details.length - 1
                                ]
                            ) === i
                        "
                        class="mt-9 primary-widget addRemove"
                        @click="addDetails()"
                    >
                        <feather-icon
                            icon="PlusIcon"
                            svgClasses="w-5 h-5 stroke-current"
                        />
                    </button>
                    <button
                        v-if="serviceDetails.details.length > 1"
                        class="mt-9 danger-widget addRemove"
                        @click="removeDetails(i)"
                    >
                        <feather-icon
                            icon="MinusIcon"
                            svgClasses="w-5 h-5 stroke-current"
                        />
                    </button>
                </vs-col>
            </vs-row>
        </vs-col>

        <div class="padding">
            <vs-col
                vs-type="flex"
                class="pa-4 flex"
                vs-align="center"
                vs-w="12"
            >
                <vs-button
                    color="primary"
                    type="filled"
                    @click="updateServices"
                    :disabled="invalidForm"
                    >Save</vs-button
                >
                <vs-button
                    color="primary"
                    type="border"
                    @click="$emit('cancel')"
                    style="margin-left: 10px"
                    >Cancel</vs-button
                >
            </vs-col>
        </div>
    </div>
</template>

<script>
import TextArea from "../text-area";

export default {
    name: "services",
    components: { TextArea },
    props: {
        item: {
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            serviceDetails: {
                km: "",
                price: "",
                details: [
                    {
                        ar: "",
                        en: ""
                    }
                ]
            }
        };
    },
    computed: {
        invalidForm() {
            if (this.serviceDetails.km && this.serviceDetails.price) {
                for (let i in this.serviceDetails.details) {
                    if (
                        !this.serviceDetails.details[i].ar ||
                        !this.serviceDetails.details[i].en
                    ) {
                        return true;
                    }
                }

                return false;
            }
            return true;
        }
    },
    methods: {
        updateServices() {
            if (
                /^[0-9]+.[0-9]+$/.test(this.serviceDetails.price.trim()) ||
                /^(none)$/.test(this.serviceDetails.price.trim())
            ) {
                this.$emit("update", this.serviceDetails);
            } else {
                this.$vs.notify({
                    title: "Error",
                    text: 'Price should be a number or "none" as a word only!',
                    color: "danger"
                });
            }
        },
        addDetails() {
            this.serviceDetails.details.push({
                ar: "",
                en: ""
            });
        },
        removeDetails(i) {
            this.serviceDetails.details = this.serviceDetails.details.filter(
                (_, it) => {
                    return i !== it;
                }
            );
        }
    },
    mounted() {
        this.serviceDetails = this.item;
    },
    beforeDestroy() {}
};
</script>

<style scoped lang="scss">
@import "src/views/basic-styles/mixins";

.flex {
    justify-content: flex-end;
    padding-right: 75px;
}

.body {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.arabic,
.english {
    width: 50%;
    display: inline-block;
    vertical-align: top;
}

.drop-down {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;

    label {
        font-size: 1rem;
        padding: 5px;
    }

    select {
        padding: 10px;
        border-radius: 5px;
        width: 90%;
        background-color: #fff;
        border: 1px solid #00000030;
    }
}

.input {
    width: 90%;
    margin-top: 10px;
}

.optionBtns {
    width: 186%;
    @include margin-x(20px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;

    .save {
        position: relative;
        left: 25px;
    }
}

.firstRow {
    padding-left: 20px;
    padding-right: 140px;
}

.label {
    margin-top: 50px;
    margin-left: 10px;
}
.addRemove {
    margin-left: 10px;
}
</style>
