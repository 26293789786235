var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5 w-full"},[_c('div',{staticClass:"firstRow"},[_c('div',{staticClass:"english"},[_c('vs-input',{staticClass:"input",attrs:{"label":"Service Name in KMs","size":"medium","danger-text":"This field is mandatory"},model:{value:(_vm.serviceDetails.km),callback:function ($$v) {_vm.$set(_vm.serviceDetails, "km", $$v)},expression:"serviceDetails.km"}})],1),_c('div',{staticClass:"arabic"},[_c('vs-input',{staticClass:"input",attrs:{"label":"Price","size":"medium","danger-text":"This field is mandatory"},model:{value:(_vm.serviceDetails.price),callback:function ($$v) {_vm.$set(_vm.serviceDetails, "price", $$v)},expression:"serviceDetails.price"}})],1)]),_c('h6',{staticClass:"vs-input--label label"},[_vm._v("Services Details Section:")]),_vm._l((_vm.serviceDetails.details),function(detail,i){return _c('vs-col',{key:i,attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"12"}},[_c('vs-row',{attrs:{"vs-align":"center","vs-type":"flex","vs-justify":"space-between","vs-w":"12"}},[_c('vs-col',{staticClass:"pa-4",attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"5"}},[_c('vs-input',{staticClass:"mt-5 w-full",attrs:{"label":`English Details NO.${i + 1}`,"rows":"7"},model:{value:(detail.en),callback:function ($$v) {_vm.$set(detail, "en", $$v)},expression:"detail.en"}})],1),_c('vs-col',{staticClass:"pa-4",attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"5"}},[_c('vs-input',{staticClass:"mt-5 w-full",attrs:{"label":`Arabic Details NO.${i + 1}`,"rows":"7"},model:{value:(detail.ar),callback:function ($$v) {_vm.$set(detail, "ar", $$v)},expression:"detail.ar"}})],1),_c('vs-col',{staticClass:"pa-4",attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"2"}},[(
                        _vm.serviceDetails.details.indexOf(
                            _vm.serviceDetails.details[
                                _vm.serviceDetails.details.length - 1
                            ]
                        ) === i
                    )?_c('button',{staticClass:"mt-9 primary-widget addRemove",on:{"click":function($event){return _vm.addDetails()}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","svgClasses":"w-5 h-5 stroke-current"}})],1):_vm._e(),(_vm.serviceDetails.details.length > 1)?_c('button',{staticClass:"mt-9 danger-widget addRemove",on:{"click":function($event){return _vm.removeDetails(i)}}},[_c('feather-icon',{attrs:{"icon":"MinusIcon","svgClasses":"w-5 h-5 stroke-current"}})],1):_vm._e()])],1)],1)}),_c('div',{staticClass:"padding"},[_c('vs-col',{staticClass:"pa-4 flex",attrs:{"vs-type":"flex","vs-align":"center","vs-w":"12"}},[_c('vs-button',{attrs:{"color":"primary","type":"filled","disabled":_vm.invalidForm},on:{"click":_vm.updateServices}},[_vm._v("Save")]),_c('vs-button',{staticStyle:{"margin-left":"10px"},attrs:{"color":"primary","type":"border"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("Cancel")])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }